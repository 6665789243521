import React, {useState, useContext, forwardRef } from 'react';
import {Formik, Field, Form} from 'formik'
import * as Yup from 'yup'
import { FirebaseContext } from '../../firebase'
import Input from '../../UI/Form/input'
import {StyledForm, StyledButton} from '../../UI/elements'
import { navigate } from 'gatsby'
import { AuthContext } from '../../firebase'
import PulseLoader from "react-spinners/PulseLoader"
import * as CONST from '../../app/constants/constants'
import { css } from "@emotion/react";
import {Link} from 'gatsby'


const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Veuillez entrer votre email'),
  password: Yup.string().required('Veuillez entrer votre mot de passe'),
})



const Login = forwardRef(({isDoctor}, ref) => {

  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const Firebase = useContext(FirebaseContext)
  const { setAuthUser } = useContext(AuthContext)
  
  const authenticateUser = async (values) => {
    const { email, password} = values
    let response
    if(isDoctor){
      response = await Firebase.loginDoc(email, password)
    }else{
      response = await Firebase.login(email, password)
    }

    console.log('response :>> ', response);

    if (response.user) { 
    setAuthUser({user: response.user})
    localStorage.setItem('user', JSON.stringify(response.user))
    response.user.isCompleted ? navigate("/app/dashboard") : navigate("/app/profile")
    }else if(response.created){
      navigate("/verify", {state:{signin: true}})
    }else{
      console.log('response :>> ', response);
      setError(response.error)
      setLoading(false)
    }
  }


  return (
    <div className="form-wrapper">
      {
        loading
        ?
          <div className="loader-container">
            <PulseLoader
              css={override}
              size={15}
              color={"#2F80ED"}
              loading={loading}
            />
          </div>
        :
          error
          ?
            <div className="error-container">
              <div className="error-message center">
                <p>
                  Une erreur est survenue:
                </p>
                <p className="message">
                  {CONST.ERRORS[error.code] ? CONST.ERRORS[error.code] : error.message}
                  {error.check && <Link to="/doctor">ici</Link>}
                </p>
                <button onClick={() => setError(null)} className="mybtn color-3 color-0-bg">R&eacute;essayer</button>
              </div>            
            </div>
          :
            <>
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={LoginSchema}
                onSubmit={(values, {setSubmitting}) =>{
                  setLoading(true)
                  authenticateUser(values)
                }}
              >
                {({isSubmitting, isValid, handleReset}) => (
                  <Form className="styled-form"  ref={ref}>
                    <Field
                      type='email'
                      name='email'
                      placeholder='Votre email ...'
                      component={Input}
                    />
                    <Field
                      type='password'
                      name='password'
                      placeholder='Votre password ...'
                      component={Input}
                    />
                    <button className="styled-button color-7-bg color-1" type='submit'>Envoyer</button>
                    {/* {props.close && handleReset} */}
                  </Form>           
                )}
              </Formik>    
            </>
      }
    </div>
  );
});

export default Login;